import React ,{useRef}from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function Chat() {

    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };
  return (
    <div>
       <div className="App">
            <button onClick={handleMinimize}></button>

            <TawkMessengerReact
                propertyId="5bf1a00379ed6453cca9f820"
                widgetId="default"
                ref={tawkMessengerRef}/>
        </div>
    </div>
  )
}
